.help-attachments {
  padding: px2em(16);
  background-color: white;
  overflow-y: auto;

  &.full-height {
    margin-top: px2em(42);
    height: calc(100vh - #{px2em(216)});
  }

  .attachment {
    overflow: hidden;
    position: relative;
    height: px2em(240);
    padding: px2em(8) !important;

    .attachment-header {
      font-size: px2em(12);
      margin-bottom: px2em(8);
      opacity: 0.9;
    }

    img {
      cursor: pointer;
      cursor: zoom-in;
      width: 100%;
    }

    .attachment-info {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.75);
      color: $primary;
      padding: px2em(8);
      height: px2em(40);

      .title {
        max-width: 70%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .btn {
        font-size: px2em(10);
        position: absolute;
        right: px2em(14);

        svg {
          margin: 0px !important;
        }
      }
    }
  }
}
