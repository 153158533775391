.ie11fix {
  /* Fixed issue in internet explorer 10-11 in CSS3 transform Property */
  position: absolute !important;
}

/* pulse ------------------------------------------ */

@-webkit-keyframes pulse {
  0% {
    transform: scale(0.96);
    -ms-transform: scale(0.96);
    -webkit-transform: scale(0.96);
    opacity: 1;
  }

  70% {
    transform: scale(0.78);
    -ms-transform: scale(0.78);
    -webkit-transform: scale(0.78);
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: scale(0.96);
    -ms-transform: scale(0.96);
    -webkit-transform: scale(0.96);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.96);
    -ms-transform: scale(0.96);
    -webkit-transform: scale(0.96);
    opacity: 1;
  }

  70% {
    transform: scale(0.78);
    -ms-transform: scale(0.78);
    -webkit-transform: scale(0.78);
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: scale(0.96);
    -ms-transform: scale(0.96);
    -webkit-transform: scale(0.96);
  }
}

/* light ------------------------------------------ */

@keyframes light {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  85% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes light {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  85% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

/* light 2 */

@-webkit-keyframes light2 {
  0% {
    opacity: 0.75;
  }

  30% {
    opacity: 0.75;
  }

  45% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 0.75;
  }
}

@keyframes light2 {
  0% {
    opacity: 0.75;
  }

  30% {
    opacity: 0.75;
  }

  45% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 0.75;
  }
}

/* rimbalzo */
@keyframes rimbalzo {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.01, 0.95) translateY(0);
  }

  30% {
    transform: scale(0.98, 1.01) translateY(0.4%);
  }

  50% {
    transform: scale(1.02, 0.98) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-0.1%);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@-webkit-keyframes rimbalzo {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.01, 0.95) translateY(0);
  }

  30% {
    transform: scale(0.98, 1.01) translateY(0.4%);
  }

  50% {
    transform: scale(1.02, 0.98) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-0.1%);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

/* ------------------------------------------- */

#main_box {
  width: px2em(350);
  height: px2em(350);
  position: relative;
  margin: 0 auto;
}

#main_box>* {
  position: absolute;
  width: 100%;
  height: auto;
  display: block;
}

/* star animation */
.star {
  animation: pulse 2s infinite;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

.star:nth-child(2n+1) {
  animation: pulse 2.8s infinite;
}

.star:nth-child(2n+3) {
  animation: pulse 1.5s infinite;
}

/* -------------- */

.screen {
  animation: light 2s infinite;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

.screen2 {
  animation: light2 0.5s infinite;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

/* pulsantiera  */

.pulsantiera {
  animation: light2 1.5 infinite;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

.pulsantiera:nth-child(2n+1) {
  animation: light2 1.7s infinite;
}

.pulsantiera:nth-child(2n+3) {
  animation: light2 1.35s infinite;
}

.pulsantiera:nth-child(2n+34) {
  animation: light2 1.8s infinite;
}

/* ---- */

#stampante {
  animation: rimbalzo 0.5s infinite;
  transform-origin: bottom center;
  -webkit-transform-origin: bottom center;
}
