.multiselect-wrapper {

  * {
    font-family: inherit !important;
    color: $main !important;
  }

  .search-wrapper {
    background-color: white;
    border: 2px solid $main-light-20 !important;
    padding: 0 !important;
    margin: 0 !important;
    min-height: px2em(44) !important;
    height: auto !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: text;

    &:focus {
      background-color: white;
    }

    input {
      padding: px2em(6) px2em(16) !important;
      margin: 0 !important;
      height: px2em(40) !important;
      border-radius: 0 !important;
      z-index: 22;
    }

    span {
      background-color: $main-light-20;
      padding: px2em(4) px2em(8) !important;
      color: white !important;
      margin: px2em(8) 2px;
      font-weight: bold;
      font-size: px2em(14);
      border-radius: px2em(24);

      i::before {
        color: white;
      }

      &:first-child {
        margin-left: px2em(4);
      }
    }
  }

  .optionListContainer {
    border-bottom-left-radius: px2em(4) !important;
    border-bottom-right-radius: px2em(4) !important;
    border-radius: 0;
    border: 2px solid $main-light-20;
    box-shadow: $shadow;
    font-size: px2em(18);
    margin-top: px2em(-2);
    z-index: 20;

    ul {
      border: 0 !important;
      border-radius: 0 !important;
      max-height: px2em(128);

      li {
        height: px2em(40) !important;
        padding: px2em(8) px2em(16) !important;
        border-radius: 0 !important;

        &:hover {
          background: $main-light-40 !important;
        }
      }
    }
  }
}
