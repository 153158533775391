.top-nav {
  background-color: white !important;
  position: fixed;
  top: 0;
  left: px2em(250);
  right: 0;
  height: px2em(60);
  z-index: 1000;
  box-shadow: $shadow;

  >div {
    display: flex;
    justify-content: space-between;

    .path,
    .right {
      height: px2em(60);
      padding: px2em(12) px2em(16);
      display: flex;
      align-items: center;
    }

    .right {
      white-space: nowrap;
      padding-left: 0;
    }
  }
}
