.campaign {
  background-color: white !important;
  margin-bottom: px2em(16);
  box-shadow: $shadow;
  border-radius: px2em(8);
  overflow: hidden;
  max-height: px2em(426);
  flex-direction: row;
  height: px2em(205);

  .campaign-crop-img {
    height: 100%;
    min-width: px2em(205);
    max-width: px2em(205);
    overflow: hidden;
  }

  .card-body {
    height: px2em(205) !important;
    padding: px2em(32) px2em(24);

    .card-subtitle {
      padding-top: px2em(8);
    }

    .validity {
      position: absolute;
      float: left;
      bottom: px2em(32);
      font-size: px2em(14);

      span {
        text-transform: uppercase;
      }
    }

    .campaign-button {
      letter-spacing: $letter-spacing;
      position: absolute;
      float: right;
      right: px2em(24);
      bottom: px2em(24);
    }
  }

  &.left {
    margin-right: px2em(8);
  }

  &.right {
    margin-left: px2em(8);
  }
}

.campaigns-table {
  tr {

    td:last-of-type,
    th:last-of-type {
      width: px2em(300) !important;
    }
  }
}

.campaigns-list div[class*="col"]:only-child .campaign {
  margin-bottom: 0;
}
