.download-panel,
.loading-panel,
.confirm-panel {
  background-color: white;
  margin: px2em(32) px2em(120) 0 px2em(120) !important;
  padding: px2em(32) !important;
  height: px2em(512);
  text-align: center;
  position: relative;

  h3 {
    font-size: px2em(22);
    font-weight: normal;
    white-space: break-spaces;
  }

  svg {
    height: px2em(280);
    padding: px2em(32);
    margin-top: px2em(16);
    margin-left: px2em(-56);
  }

  .btn-link {
    position: absolute;
    bottom: px2em(16);
    left: 0;
    right: 0;
  }
}

.download-panel {
  svg {
    margin-left: px2em(-16);
  }

  .download-disclaimer {
    margin: 0 auto;
    line-height: px2em(20);
    max-width: px2em(300);
    font-size: px2em(18);

    h3 {
      line-height: px2em(20);
      font-size: px2em(18);
    }

    button {
      margin-left: 2px;
      margin-top: -0.0675em;
      padding: 0 !important;
      text-transform: lowercase;
      line-height: inherit;
      position: relative;
      bottom: 0;
    }
  }
}

.loading-panel {
  svg {
    margin-left: px2em(0);
  }
}

.confirm-panel {
  h3 {
    font-size: px2em(18);
  }
}
