.preview {
  position: fixed;
  top: px2em(60);
  bottom: 0;
  right: 0;
  left: px2em(250);
  height: 100%;
  width: calc(100vw - #{px2em(250)});
  background-color: $light-bg;
  z-index: -1;
  cursor: pointer;
  cursor: zoom-out;
  box-shadow: none !important;
  outline: none !important;
  opacity: 0;

  &.show {
    z-index: 200;
    opacity: 1;
  }

  .preview-wrapper {
    width: 80%;
    max-width: px2em(800);
    position: absolute;
    top: calc(50% - #{px2em(30)});
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
  }

  img {
    max-height: calc(100vh - #{px2em(200)});
    max-width: 100%;
    margin: 0 auto;
    display: block;
    cursor: initial;
  }

  .video-player {
    position: relative;
    padding-bottom: 56%;
    width: 100%;
    height: 0;

    video {
      position: absolute;
      top: 0;
      left: 0;
      cursor: initial;
    }
  }
}