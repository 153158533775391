.news-crop-img-large,
.campaign-crop-img-large,
.gadget-crop-img-large {
  height: px2em(445);
  overflow: hidden;
  margin: px2em(-16);
  margin-bottom: px2em(16);
}

.news-detail,
.campaign-detail,
.gadget {
  padding-right: 20% !important;

  .title {
    font-size: px2em(20);
    font-weight: bold;
    line-height: px2em(20);
  }

  .date {
    padding-top: px2em(16);
  }

  .subtitle {
    color: $main-light-20;
    font-size: px2em(18);
  }

  .text.ql-container,
  .editor {
    padding-top: px2em(16);
    border: 0;
    font-family: inherit;
    font-size: px2em(16);
    height: auto;

    .ql-editor {
      padding: 0;
    }

    .table {
      background-color: white;
      border-radius: px2em(8);
      border: 0;

      tr {
        &:not(:last-of-type) {
          border-bottom: 2px solid $main-light-40;
        }

        td {
          border: 0;
          padding: px2em(8) px2em(16);
        }
      }
    }
  }

  .btn {
    float: right;
    position: relative;
  }
}

/* VIDEO */

.news-video,
.campaign-video {
  height: 45vh;
  background-color: $main-dark-40;
  margin: px2em(-16);
  margin-bottom: px2em(16);

  .video-player {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
}
