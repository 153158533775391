.gadget-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wizard-content {
    justify-content: flex-start;
    flex-grow: inherit;

    .wizard-images {
      height: auto !important;
      max-height: calc(100vh - 14em);
      padding: 0 px2em(16) !important;
      padding-left: 0 !important;
    }
  }

  img {
    height: auto !important;
    object-fit: contain;
  }

  .card {
    .card-body {
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: px2em(24) px2em(16);
      height: auto;
      max-height: inherit;

      .details-table {
        margin-top: px2em(20);
        width: 100%;
        max-width: px2em(300);

        tr td {
          vertical-align: middle;
          min-height: px2em(30);
          font-size: 1.125em;

          &:last-child {

            font-weight: 600;
            text-align: right;
          }
        }
      }
    }

    .category {
      font-size: 1.125em;
    }

    .button-box {
      flex-grow: 1;
      display: flex;
      align-items: end;
      justify-content: flex-end;
      margin-top: px2em(20);
      margin-bottom: px2em(10);

      .btn {
        margin-left: px2em(15);
      }
    }
  }
}

.block-other-characteristics {
  margin-top: px2em(20);

  p {
    font-size: 1.125em;
  }

  .label {
    font-weight: 600;
  }
}


div.header-attachment[class*=col] {
  margin-top: px2em(30);
  margin-bottom: px2em(20);
}

div.col-attachment[class*=col] {
  margin-bottom: px2em(30);
}
