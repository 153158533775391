.customize-parameters {
  height: calc(100vh - #{px2em(272)});
  overflow: visible;
  border-radius: 0;

  .card-body {
    max-height: none !important;
    height: calc(100vh - #{px2em(272)});
    overflow-y: auto;
    padding-right: px2em(16);
    border-radius: 0;

    @include scrollbar;

    .btn-group {
      width: 100%;

      .btn {
        width: 33%;

        &:hover {
          z-index: 0;
        }
      }
    }

    .disclaimer {
      font-size: px2em(16);
      margin-top: px2em(16);

      svg {
        margin-right: px2em(8);
        font-size: px2em(20);
      }
    }
  }

  .customization-type {
    .card-header {
      margin-top: px2em(-14);
    }

    .card-body {
      padding-right: 0;
      height: auto;
    }
  }

  /* PARAMETERS ------------------------------------------- */

  .parameter {
    .card-body {
      padding: px2em(16) !important;
      background-color: white;
      border-radius: px2em(4) !important;
      height: auto !important;
    }

    /* MONEY ----------------------------------------- */

    &.parameter-checkbox {
      .form-check {
        padding-left: 0 !important;

        label {
          margin-bottom: 0 !important;
        }
      }

      span svg {
        font-size: px2em(14);
        margin-top: px2em(-1);
      }
    }

    /* CHECKBOX ----------------------------------------- */

    &.parameter-checkbox {
      .form-check {
        padding-left: 0 !important;

        label {
          margin-bottom: 0 !important;
        }
      }

      span svg {
        font-size: px2em(14);
        margin-top: px2em(-1);
      }
    }

    /* LOGO ---------------------------------------------- */

    &.parameter-logo {
      .logos {
        .selected {
          border-radius: px2em(4);
          background-color: $main-light-40;
        }

        div[class*="col"] {
          padding: px2em(16) !important;

          img {
            padding: px2em(16);
            background-color: $main-light-20;
            width: 100%;
            max-height: px2em(80);
            cursor: pointer;
          }
        }
      }
    }

    /* ADVANCED ------------------------------------------ */

    &.parameter-advanced {
      margin-bottom: 0;

      form {
        padding: 0 !important;

        textarea {
          min-height: px2em(72);
          resize: vertical;
        }
      }
    }

    &.parameter-money {
      form {
        padding: 0 !important;

        input {
          width: 100% !important;
        }
      }

      div[class*="col"] {
        &:nth-child(even) {
          padding-left: px2em(8) !important;
        }

        &:nth-child(odd) {
          padding-right: px2em(8) !important;
        }

        &:nth-child(n+3) {
          padding-top: px2em(8) !important;
        }
      }
    }

    /* CARD DESIGN ---------------------------------------- */

    &.parameter-card-design {
      .disclaimer {
        font-size: px2em(14);
        margin-bottom: px2em(16);

        svg {
          margin-right: px2em(8);
          font-size: px2em(20);
        }
      }

      .custom-cards {
        margin-bottom: px2em(24);

        .selected {
          border-radius: px2em(4);
          background-color: $main-light-40;
        }

        img {
          width: 100%;
          cursor: pointer;
        }
      }

      .default {
        font-size: px2em(14);
        text-align: right;
      }
    }
  }
}
