.documents {
  padding: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  left: px2em(250);
  top: px2em(60);
  bottom: px2em(38);
  overflow: hidden;
  overflow-x: auto;

  .row {
    display: flex;
    flex-wrap: nowrap !important;
  }

  .left {
    flex: 0 0 auto;
    box-shadow: $shadow;
  }

  .right {
    flex: 1 1 auto;
    right: 0;
    height: calc(100vh - #{px2em(100)});
  }
}

.documents+#powered-by {
  position: absolute;
  bottom: px2em(16);
  margin-left: 0;
}
