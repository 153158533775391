// see _news-detail.scss
.campaign-detail,
.gadget {
  .campaign-crop-image-large,
  .gadget-crop-image-large {
    height: px2em(445);
  }

  .validity {
    margin-top: px2em(16);
    border-left: px2em(4) solid $secondary;
    padding-left: px2em(8);

    span {
      text-transform: uppercase;
    }
  }

  .text.ql-container {
    padding-top: px2em(32);
    padding-bottom: px2em(40);
  }

  .divider {
    margin-top: px2em(32);
    margin-bottom: px2em(32);
  }

  .no-data {
    position: relative;
  }

  &.formazione-rete {
    min-height: calc(100vh - #{px2em(116)}) !important;
  }
}
