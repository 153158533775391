.card.crd.order-summary,
.card.crd.customize-form {
  height: calc(100vh - #{px2em(272)});
  overflow: visible;

  .card-body {
    padding: px2em(16) !important;
    background-color: white;
    border-radius: px2em(4);
    max-height: none !important;
    overflow: auto;

    @include scrollbar;

    form {
      width: 100%;
      max-height: none !important;
      padding: 0 !important;
      display: flex;
      flex-flow: column;

      .form-check,
      .form-switch {
        font-size: px2em(16);
        font-weight: bold;

        label {
          text-transform: none;
          margin-bottom: 0 !important;
        }

        span svg {
          font-size: px2em(14);
        }
      }

      // CHECK ---------------------------------
      .form-check {
        padding-left: 0;
        margin-bottom: px2em(16);
      }

      // SWITCH ---------------------------------
      .form-switch {
        div {
          float: right;
        }
      }

      // TEXTAREA -------------------------------
      .form-textarea {
        flex: 1;

        textarea.form-control {
          width: 100%;
          height: calc(100% - #{px2em(28)});
          min-height: px2em(24);
          box-sizing: border-box;
          resize: none;
        }
      }

      .form-group {
        margin-bottom: px2em(16);

        &.form-select {
          margin-bottom: px2em(64);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .disclaimer {
        margin: px2em(16) 0;
      }

      .form-radio {
        span:nth-child(2) {
          font-family: inherit !important;
          font-size: px2em(18);
          text-transform: none;
          letter-spacing: normal;
          color: $main;
        }
      }

      .disabled-inputs {
        background: white;
        opacity: 0.15;
        height: 100%;
        width: 100%;
        z-index: 22;
      }

      input:disabled,
      textarea:disabled {
        border-color: $main-light-40 !important;
        background-color: white;
      }

      // AUTOCOMPLETE
      .new-address {
        .btn {
          width: px2em(32) !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.card.crd.order-summary {
  .card-body {
    label {
      color: $secondary;
    }

    .form-check label,
    .form-switch label {
      color: $main;
    }

    input,
    textarea,
    .select span {
      border-color: $main-light-40 !important;
    }

    .select span::after {
      color: transparent;
    }
  }
}
