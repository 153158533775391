.help-chat-editor {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: px2em(16);
  box-shadow: $shadow;

  .input-group {
    border: 0 !important;
    margin-bottom: 0;

    .input-group-append {
      top: 0;
      bottom: 0;
      border-top-right-radius: px2em(4);
      border-bottom-right-radius: px2em(4);
      overflow: hidden;

      .btn-link {
        color: $main-light-20;
      }

      .btn-link:first-of-type {

        &:hover {
          color: $secondary !important;
        }
      }

      .ladda-button {
        width: auto;
        padding: px2em(6) !important;

        .ladda-label {
          margin-top: px2em(-3);
        }
      }
    }

    /* FILE UPLOADER */
    .file-uploader {
      width: 100%;
      margin-top: px2em(16);
    }

    /* TEXTAREA */
    .form-control {
      padding-right: px2em(90);
      border-top-left-radius: px2em(4);
      border-bottom-left-radius: px2em(4);
      border: 2px solid $main-light-20;
      height: px2em(40);

      &:focus {
        box-shadow: none;
        border: 2px solid $secondary;
        z-index: 1;
      }
    }
  }
}
