.table-wrapper {
  position: relative;
  height: 100%;

  .orders-table,
  .campaigns-table,
  .users-table {
    margin-bottom: 0 !important;
    border-color: $light-bg;
    table-layout: fixed;
    background-color: white;

    tr {
      th {
        border-color: $light-bg;
        color: $secondary;
        font-size: px2em(14);
        padding: px2em(32) px2em(24) px2em(14) px2em(32);
        letter-spacing: $letter-spacing;
        font-weight: 500;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
      }

      td {
        border-color: $light-bg;
        font-size: px2em(16);
        padding: px2em(14) px2em(12);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      td:first-of-type {
        color: $primary;
        font-weight: bold;
      }

      td:nth-child(3) {
        text-align: center;
      }

      td:first-of-type,
      th:first-of-type {
        padding-left: px2em(24);
      }

      td:last-of-type,
      th:last-of-type {
        padding-right: px2em(24);
        text-overflow: initial;
        text-align: right;
      }

      th:last-of-type {
        width: auto;
        text-align: center;
      }
    }
  }

  .pagination {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;

    .page-item {
      margin: 0 2px;

      .page-link {
        border: 0;
        font-size: px2em(16);
        height: px2em(32);
        width: px2em(32);
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 50%;
        box-shadow: none !important;

        svg {
          height: px2em(18);
          width: px2em(18);
          font-size: 16px;
          color: $main;
        }
      }

      &.current .page-link,
      &:not(.disabled) .page-link:hover {
        background-color: $main-light-40;
        font-weight: bold;

        svg {
          color: $primary;
        }
      }

      &.disabled {
        cursor: not-allowed;

        .page-link {

          svg {
            color: $main-light-20;
          }
        }
      }
    }
  }
}
