.DayPickerInput {
  display: block !important;

  input {
    border: 2px solid $main-light-20;
    font-size: px2em(18);
    background: transparent;
  }

  input:focus {
    border: 2px solid $secondary;
  }

  /* DATE PICKER */

  .DayPicker-Caption {
    text-align: center;
  }

  .DayPicker-NavButton {
    position: absolute;
    top: px2em(20);
  }

  .DayPicker-NavButton--prev {
    left: px2em(20);
  }

  .DayPicker-NavButton--next {
    right: px2em(20);
  }

  .DayPicker-Weekday {
    text-transform: uppercase;
    word-spacing: 0.04em;
    color: $secondary;
  }

  .DayPickerInput-Overlay {
    border-radius: px2em(4);
    box-shadow: $shadow;
    z-index: 999;
    margin-bottom: px2em(16);
  }

  .DayPicker-Day--today {
    color: $secondary !important;
  }

  .DayPicker-Day--selected {
    background-color: $secondary !important;
    border-radius: px2em(4);
    color: white !important;
  }

  .DayPicker-Day:hover {
    background-color: $main-light-40;
    border-radius: px2em(4);
  }

  .DayPicker-Day--disabled:hover,
  .DayPicker-Day--outside:hover {
    background-color: transparent;
  }
}
