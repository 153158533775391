.notifications {
  margin-right: px2em(24);

  .badge-notify {
    position: absolute;
    top: px2em(-8);
    left: px2em(-20);
    font-size: px2em(8);
    padding: px2em(10) !important;
    background-color: $primary;
    color: white;
    padding: px2em(10) !important;
    width: auto;
    height: px2em(30);
    border-radius: px2em(15);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .notification-button {
    font-size: px2em(24) !important;
    font-weight: bold;
  }

  .dropdown-menu {
    margin-top: px2em(48);
    width: auto;
    max-height: px2em(228);
    overflow-y: auto;

    .dropdown-item {
      padding: px2em(8) px2em(16);

      &.unread {
        background-color: $main-light-40;
      }
    }

    @include scrollbar;
  }
}
