.card {
  background-color: transparent;
  border: 0 !important;
  margin-bottom: px2em(16);

  h3.card-header {
    font-size: px2em(22) !important;
    font-weight: bold;
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
    line-height: px2em(40) !important;

    .subtitle {
      font-weight: bold;
      color: $danger;
      font-size: px2em(10);
      display: block;
      line-height: px2em(24);
      margin-top: px2em(-8);
      padding-bottom: px2em(8);
    }

    div {
      height: px2em(40);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a:first-of-type,
    button:first-of-type {
      margin-left: px2em(32);
      font-size: px2em(12);
    }

    .text-right {
      padding: 0 !important;
      font-weight: normal;
      font-size: px2em(12);

      span,
      a,
      button {
        margin-left: 0;
        padding-right: px2em(8);
        font-size: px2em(18) !important;
        color: $primary;
      }
    }
  }

  .card-body {
    padding: 0;
    border-radius: px2em(4);
    overflow: hidden;
    background-color: transparent;
    max-height: px2em(432);

    .card-title {
      font-size: px2em(20);
      font-weight: bold;
      margin: 0;
      padding: 0;

      a {
        color: inherit;
      }
    }

    .card-subtitle {
      color: $main-light-20;
      font-size: px2em(18);
      font-weight: 400;
      padding: 0;
    }

    .card-text {
      font-size: px2em(16);
      padding: 0;
    }
  }
}

.crd {
  padding: 0;
}

.full-heigth {
  .card-body {
    height: px2em(432);

    .no-data-table {
      height: px2em(368);
      display: flex;
      align-items: center;
    }
  }
}
