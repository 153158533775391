.order-form {
  h4 {
    font-weight: 400 !important;
  }

  .order-status {
    width: px2em(218);
    position: absolute;
    right: 0;
    top: 0;
    font-size: px2em(8);
  }

  .address {
    font-size: px2em(16);
    color: $main-light-20;
  }

  label {
    color: $secondary;

    span {
      color: $main;
      font-weight: 400;
      margin-left: px2em(8);
      letter-spacing: 0;
    }
  }

  .order-crop-img {
    height: px2em(196);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      margin: 0 auto;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      cursor: zoom-in;
    }
  }

  .order-info {
    padding-left: px2em(24) !important;
    padding-right: 0;

    button {
      padding: 0;
      font-size: px2em(12);
      line-height: px2em(22);
      float: right;
    }

    button:focus,
    button:active {
      font-size: px2em(12);
    }

    .order-branch {
      color: $primary;
      margin-left: px2em(4);
      font-weight: 400;
    }

    .order-type-quantity {
      margin: px2em(14) px2em(8);
      margin-bottom: px2em(16);
      font-size: px2em(18);

      input {
        margin-left: px2em(16);
        display: inline;
        font-size: px2em(14);
      }
    }

    .form-control {
      border: 2px solid $main-light-20;

      &:focus {
        border: 2px solid $secondary;
      }

      &:disabled {
        border: 2px solid $main-light-40;
      }
    }
  }

  .order-footer {
    margin-top: px2em(24);

    button {
      float: right;
      margin-left: px2em(24);
    }
  }
}
