.order-status {
  font-weight: 500;
  line-height: normal;
  font-size: px2em(10);
  text-align: right;
  text-transform: uppercase;
  outline: none;

  .progress {
    margin-top: 2px;
    height: px2em(8) !important;

    .progress-bar {
      border-radius: px2em(4);
    }
  }
}
