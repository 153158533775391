@mixin scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 px2em(6) rgba(0, 0, 0, 0.3);
    border-radius: px2em(10);
    background-color: $light-bg;
  }

  &::-webkit-scrollbar {
    width: px2em(12);
    background-color: $light-bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: px2em(10);
    -webkit-box-shadow: inset 0 0 px2em(4) rgba(0, 0, 0, 0.3);
    background-color: $main-light-40;
  }
}
