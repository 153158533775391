/* VARIABLES OVERRIDE */
@import "variables";

/* LADDA BUTTONS */
@import "~ladda/css/ladda.scss";

/* BOOTSTRAP */
@import '~bootstrap/scss/bootstrap';

/* MATERIAL-UI ICON */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* TOASTIFY */
@import "~react-toastify/scss/main.scss";

/* KENDO */
@import "~@progress/kendo-theme-default/scss/autocomplete/_index.scss";
@import "~@progress/kendo-theme-default/scss/combobox/_index.scss";
@import "~@progress/kendo-theme-default/scss/dropdownlist/_index.scss";
@import "~@progress/kendo-theme-default/scss/dropdowntree/_index.scss";
@import "~@progress/kendo-theme-default/scss/multiselect/_index.scss";

/* FONT */
@import "fonts";

/* CUSTOM */
@import "mixins";

/* CUSTOM */
@import "custom";
@import "layout";

/* ELEMENTS */
@import "elements/badge";
@import "elements/loading";
@import "elements/modal";
@import "elements/preview";
@import "elements/show-more";
@import "elements/table";
@import "elements/toast";
@import "elements/wip";

/* ELEMENTS ANIMATIONS */
@import "elements/animations/print-loading";

/* ELEMENTS INPUTS */
@import "elements/inputs/auto-complete";
@import "elements/inputs/buttons";
@import "elements/inputs/date-picker";
@import "elements/inputs/file-uploader";
@import "elements/inputs/filters";
@import "elements/inputs/input-group";
@import "elements/inputs/inputs";
@import "elements/inputs/multiselect";
@import "elements/inputs/select";
@import "elements/inputs/switch";

/* ELEMENTS WRAPPERS */
@import "elements/wrappers/card";
@import "elements/wrappers/form";
@import "elements/wrappers/tabs";

/* CAMPAIGNS */
@import "campaigns/campaign-preview";
@import "campaigns/campaign-detail";

/* CUSTOMIZE */
@import "customize/customize-form";
@import "customize/customize-parameters";
@import "customize/panels";
@import "customize/stepper";
@import "customize/wizard";

/* DOCUMENTS */
@import "documents/custom-icons";
@import "documents/documents";
@import "documents/file-preview";
@import "documents/finder";

/* FAQ */
@import "faq/faq";

/* HELP */
@import "help/help-attachments";
@import "help/help-chat";
@import "help/help-chat-editor";
@import "help/help-preview";
@import "help/need-help";

/* MATERIALS */
@import "materials/materials-list";
@import "materials/material-preview";

/* NAVBAR */
@import "navbar/notifications-menu";
@import "navbar/path";
@import "navbar/search-bar";
@import "navbar/side-nav";
@import "navbar/top-nav";
@import "navbar/user-menu";

/* NEWS */
@import "news/news-detail";
@import "news/news-preview";

/* ORDERS */
@import "orders/order-detail";
@import "orders/order-form";
@import "orders/order-preview";
@import "orders/order-status";

/* GADGETS */
@import "gadgets/gadgets";

/* PROFILE */
@import "profile/profile";

/* USERS */
@import "users/user-modal";
@import "users/users-list";

/* MEDIA QUERIES */
@import "mq";
