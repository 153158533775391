div[class^="custom-icon-"] {
  background-repeat: no-repeat;
  height: px2em(20);
  width: px2em(20);
  background-color: transparent !important;
  mask-size: px2em(20);
  mask-repeat: no-repeat;
}

.custom-icon-doc {
  background-image: url(../../icons/doc.svg);
}

.custom-icon-file {
  background-image: url(../../icons/file.svg);
  background-size: px2em(20);
}

.custom-icon-folder {
  background-image: url(../../icons/folder.svg);
  background-size: px2em(20);
}

.custom-icon-help {
  background-image: url(../../icons/help.svg);
  height: px2em(23);
  width: px2em(19);
  background-size: px2em(18);
}

.custom-icon-img {
  background-image: url(../../icons/img.svg);
}

.custom-icon-pdf {
  background-image: url(../../icons/pdf.svg);
}

.custom-icon-zip {
  background-image: url(../../icons/zip.svg);
}