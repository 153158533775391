.badge {
  text-transform: uppercase;
  padding: px2em(8) px2em(14) !important;
  font-size: px2em(14);
  width: px2em(152);
}

span[class^="badge-outline-"] {
  background-color: transparent !important;
  letter-spacing: $letter-spacing !important;
}

.badge-outline-success {
  color: $success !important;
  border: 2px solid $success !important;
}

.badge-outline-info {
  color: $info !important;
  border: 2px solid $info !important;
}

.badge-outline-warning {
  color: $warning !important;
  border: 2px solid $warning !important;
}

.badge-outline-danger {
  color: $danger !important;
  border: 2px solid $danger !important;
}
