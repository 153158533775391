.faq-list {
  min-height: calc(100vh - #{px2em(188)});

  .faq-accordion {
    background-color: white;

    .icon-button {
      background-color: transparent !important;
      width: 100% !important;
      font-size: px2em(20);
      line-height: px2em(24);
      border: 0;
      text-align: left;
      color: $primary !important;
      padding: px2em(14) !important;

      svg {
        float: right;
      }
    }

    .card-body {
      max-height: none;
    }

    .faq-content {
      padding: px2em(16) !important;
      padding-top: 0 !important;
      font-size: px2em(16) !important;

      * {
        box-shadow: none !important;
      }

      .icon-button {
        font-size: px2em(18);
        color: $main !important;
      }

      .faq-accordion {
        border-top: 1px solid $main-light-20 !important;
        border-radius: 0 !important;
        margin: 0 !important;

        &:last-of-type {
          border-bottom: 1px solid $main-light-20 !important;
        }

        &:nth-child(odd) {
          background-color: $light-bg !important;
        }
      }
    }
  }
}
