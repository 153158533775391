.wizard,
.gadget-detail {
  .wizard-content {
    padding: 0 !important;
    flex-grow: 1;

    &.row1 {
      min-height: px2em(450);

      .card {
        height: 100%;
      }
    }

    .wizard-images {
      padding: px2em(24) px2em(16) !important;
      height: calc(100vh - #{px2em(224)});
      overflow: hidden;
      text-align: right;
      position: relative;

      img {
        width: auto;
        max-width: calc(100% - #{px2em(48)});
        max-height: 100%;
        margin: 0 auto;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .wizard-step {
      padding: px2em(24) px2em(16) !important;
      height: calc(100vh - #{px2em(224)});
    }
  }

  .wizard-buttons {
    position: absolute;
    right: px2em(32);
    bottom: px2em(48);
    text-align: right !important;

    button {
      margin-left: px2em(24);
    }
  }
}
