/* NORMAL ---------------------------------------- */

@font-face {
  font-family: 'CustomFont';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/KarbonApp.eot");
  src: url("../fonts/KarbonApp.eot") format("embedded-opentype"), url("../fonts/KarbonApp.woff2") format("woff2"), url("../fonts/KarbonApp.woff") format("woff"), url("../fonts/KarbonApp.ttf") format("truetype"), url("../fonts/KarbonApp.otf") format("opentype");
}

/* BOLD ---------------------------------------- */

@font-face {
  font-family: 'CustomFont';
  font-style: bold;
  font-weight: 600;
  src: url("../fonts/KarbonAppMedium.eot");
  src: url("../fonts/KarbonAppMedium.eot") format("embedded-opentype"), url("../fonts/KarbonAppMedium.woff2") format("woff2"), url("../fonts/KarbonAppMedium.woff") format("woff"), url("../fonts/KarbonAppMedium.ttf") format("truetype"), url("../fonts/KarbonAppMedium.otf") format("opentype");
}

/* LIGHT ---------------------------------------- */

@font-face {
  font-family: 'CustomFont';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/KarbonAppLight.eot");
  src: url("../fonts/KarbonAppLight.eot") format("embedded-opentype"), url("../fonts/KarbonAppLight.woff2") format("woff2"), url("../fonts/KarbonAppLight.woff") format("woff"), url("../fonts/KarbonAppLight.ttf") format("truetype"), url("../fonts/KarbonAppLight.otf") format("opentype");
}

/* ITALIC ---------------------------------------- */

@font-face {
  font-family: 'CustomFont';
  font-style: italic;
  src: url("../fonts/KarbonAppItalic.eot");
  src: url("../fonts/KarbonAppItalic.eot") format("embedded-opentype"), url("../fonts/KarbonAppItalic.woff2") format("woff2"), url("../fonts/KarbonAppItalic.woff") format("woff"), url("../fonts/KarbonAppItalic.ttf") format("truetype"), url("../fonts/KarbonAppItalic.otf") format("opentype");
}
