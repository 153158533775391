.help-request-preview {
  background-color: white !important;
  margin-bottom: px2em(16);
  box-shadow: $shadow;
  border-radius: px2em(4);
  overflow: hidden;
  max-height: px2em(404);
  max-width: px2em(1250);

  .card-body {
    max-height: px2em(124) !important;
    padding: px2em(12) px2em(16);

    .card-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: px2em(120);
    }

    .card-subtitle {
      padding-top: px2em(8);
      padding-bottom: px2em(8);
    }

    .help-request-button {
      float: right;
      right: px2em(32);
      top: px2em(36);
      letter-spacing: $letter-spacing;
      position: absolute;
      box-shadow: none;
    }
  }
}

.help-requests-list {}
