.file-preview {
  max-width: none !important;
  min-width: px2em(500);

  .header {
    .name {
      color: $primary;
      font-weight: bold;

      button {
        margin-right: px2em(16);
        cursor: default;
      }
    }
  }

  .viewer {

    .zip,
    img {
      padding-top: px2em(32);
      display: block;
      margin: 0 auto;
      max-height: calc(100vh - #{px2em(112)});
    }

    iframe {
      height: calc(100vh - #{px2em(112)});
    }
  }
}
