.select {
  width: 100%;

  span {
    background-color: white;
    border-radius: px2em(4);
    border: 2px solid $main-light-20;
    color: $main;
    font-size: px2em(18);
    height: px2em(40);
    outline: none;
    overflow: hidden;
    padding: px2em(6) px2em(16);
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    z-index: 100;
    cursor: pointer;

    &.disabled {
      border-color: $main-light-40;
      cursor: default;

      &:after {
        color: $main-light-40;
      }
    }

    &:after {
      margin-top: px2em(10);
      float: right;
    }
  }

  .dropdown-menu {
    background: white;
    border-bottom-left-radius: px2em(4) !important;
    border-bottom-right-radius: px2em(4) !important;
    border-radius: 0;
    border: 2px solid $main-light-20;
    box-shadow: $shadow;
    font-size: px2em(16);
    margin: -2px 0 px2em(24) 0 !important;
    max-height: px2em(128);
    overflow: auto;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    transform: translate3d(0, px2em(44), 0) !important;
    width: 100%;
    z-index: 99;
    left: 0 !important;

    .dropdown-item {
      width: auto;
    }

    div {
      border-bottom: 2px solid $main-light-40;
      color: $main;
      cursor: pointer;
      height: px2em(40);
      padding: px2em(8) px2em(16);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    div:hover {
      background: $main-light-40;
    }

    div:focus {
      outline: none;
    }

    div:last-child {
      border-bottom: 0;
    }
  }
}
