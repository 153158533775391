// INPUT GROUP (dynamic input)
.input-group {
  margin-bottom: px2em(16);
  border: 2px solid $secondary;
  border-radius: px2em(4);
  box-sizing: border-box;

  &.disabled {
    border: 2px solid $main-light-20 !important;

    input {
      color: $main-light-20 !important;
    }
  }

  input {
    font-size: px2em(18);
    border: 0 !important;
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: px2em(40);
  }

  .input-group-append {
    right: 0;
    position: absolute;

    .btn-link.icon {
      color: $main !important;
      margin-right: px2em(8);

      svg {
        margin-top: px2em(5);
        font-size: px2em(16) !important;
      }
    }

    .input-group-text {
      background-color: white;
      border: 0;
      font-size: px2em(14);
      color: $main;
      margin-top: px2em(4);
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .input-group {
    .input-group-append {
      .input-group-text {
        background-color: transparent;
      }
    }
  }
}
