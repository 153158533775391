.search,
.news,
.news-large,
.alert,
.alert-large {
  background-color: white !important;
  margin-bottom: px2em(16);
  box-shadow: $shadow;
  border-radius: px2em(4);
  overflow: hidden;
  max-height: px2em(404);

  .news-crop-img {
    height: px2em(304);
    width: 100%;
    overflow: hidden;
  }

  .card-body {
    max-height: px2em(124) !important;
    padding: px2em(12) px2em(16);

    .news-category,
    .news-featured-category {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $main-light-20;
      color: white;
      border-bottom-left-radius: px2em(8);
      padding: px2em(4);

      svg {
        font-size: px2em(20);
        display: block;
        margin: 0 auto;
      }
    }

    .news-featured-category {
      padding: px2em(6);

      svg {
        font-size: px2em(22);
      }
    }

    .search-button,
    .news-button,
    .news-large-button {
      letter-spacing: $letter-spacing;
      position: absolute;
      box-shadow: none;
    }

    .news-button {
      margin-right: px2em(24);
      margin-top: px2em(-30);
      right: 0;
    }

    .search-button,
    .news-large-button {
      float: right;
      right: px2em(32);
      top: px2em(36);
    }

    .search-title,
    .news-title,
    .video-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.large {
        margin-right: px2em(120);
      }
    }

    .news-title {
      &.trunk {
        margin-right: px2em(96);
      }
    }

    .video-title {
      &.trunk {
        margin-right: px2em(110);
      }

      &.large {
        margin-right: px2em(135);
      }
    }

    .search-subtitle,
    .news-subtitle,
    .video-subtitle {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-top: px2em(8);

      &.large {
        margin-right: px2em(110);
      }
    }

    .video-subtitle {
      &.large {
        margin-right: px2em(150);
      }
    }

    .card-text {
      height: px2em(80);
      overflow: hidden;

      &.empty {
        height: 0;
        padding: 0;
      }
    }

    .date {
      padding-top: px2em(32);
    }
  }
}

// ALERT ----------------------------------------------

.alert {
  padding: 0;
  flex-direction: row;

  img {
    background: $secondary;
    border: px2em(2.5) solid $secondary;
    border-radius: 0 px2em(4) px2em(4) 0;
    margin-top: px2em(15);
    margin-bottom: px2em(15);
    width: 0;
  }

  .card-text {
    padding-top: px2em(4) !important;

    &.empty {
      padding: px2em(12) !important;
    }
  }
}

.featured {
  .news {
    margin-bottom: 0;
    height: px2em(426);

    .date {
      padding-top: px2em(22);
    }
  }
}

.news-list .news-highlight {

  // &:first-of-type {
  .news {
    border: 2px solid $primary !important;
  }

  // }
}

// LARGE ---------------------------------------------------

.search,
.news-large,
.alert-large {
  flex-direction: row;
  height: px2em(110);
  border-radius: px2em(8);
  max-width: px2em(1250);

  .search-crop-img,
  .news-crop-img {
    width: 100%;
    height: auto;
    min-width: px2em(250);
    max-width: px2em(250);
  }

  .card-subtitle {
    font-size: px2em(18);
  }

  .date {
    padding-top: px2em(16) !important;
  }

  .news-img {
    border-radius: 0;
    background: white;
    border: px2em(125) solid white;
    width: 0;
  }
}

.alert-large {
  img {
    border-radius: 0;
    background: $secondary;
    border: px2em(125) solid $secondary;
    width: 0;
  }

  .card-text {
    padding-top: 2px !important;

    &.empty {
      padding: px2em(12) !important;
    }
  }
}

// DATE ----------------------------------------------

.date {
  color: $secondary !important;
  font-size: px2em(14) !important;
  font-weight: bold !important;
  letter-spacing: $letter-spacing !important;
}

// LIST ----------------------------------------------

.help-requests-list,
.orders-list,
.campaigns-list,
.news-list {
  div[class^="col"]:last-child {
    .card {
      // margin-bottom: 0;

    }
  }
}

.help-requests-list,
.news-list,
.campaigns-list,
.orders-list,
.faq-list {
  margin-bottom: px2em(16);
  padding-bottom: px2em(16);

  .orders-date,
  .news-date {
    padding: 0;
    border-bottom: 1px solid $main-light-40;
    margin-bottom: px2em(16);

    h5 {
      font-weight: bold;
      color: $main-light-20;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
