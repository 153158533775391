.btn-outline-primary {
  border: 2px solid $main-light-40 !important;
  font-weight: bold !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  border: 2px solid $main-light-40 !important;
  background-color: transparent !important;
  color: $primary-sat-40 !important;
  font-weight: bold !important;
}

.btn,
.btn:active {
  box-shadow: none !important;
  line-height: px2em(18);
  font-size: px2em(16);
}

.icon-button {
  padding: px2em(6) !important;

  svg {
    font-size: px2em(24);
  }
}

.btn-link {
  color: $primary;
}

.btn-link:hover,
.btn-link:active {
  color: $primary !important;
  text-decoration: none;
}

.btn-link.icon:hover,
.btn-link.icon:active {
  color: $main-dark-40 !important;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3 !important;
  cursor: initial !important;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3 !important;
}

.btn.disabled.btn-primary:hover,
.btn.disabled.btn-primary:active,
.btn.disabled.btn-primary:focus {
  background-color: $primary !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: white;
  background-color: $primary-sat-40 !important;
}

.btn {
  padding: px2em(8) px2em(18);
  font-weight: bold;
}

.ladda-button {
  padding: px2em(8) px2em(18) !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ladda-label {
  font-size: px2em(16) !important;

  svg {
    margin-top: 2px !important;
  }
}
