.path {
  overflow: hidden;

  .breadcrumb {
    background-color: white;
    color: inherit;
    font-size: px2em(20);
    line-height: px2em(16);
    font-weight: bold;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    li a {
      color: $primary;
    }

    li:last-child a {
      color: $main;
    }

    .breadcrumb-item {
      padding: 0;
    }

    .breadcrumb-item+.breadcrumb-item::before {
      display: none;
    }
  }
}
