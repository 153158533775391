/* CSS-VARS */
@import "~css-vars/css-vars";
$css-vars-use-native: true;

/* STANDARD COLORS */
$danger: #F9423A; // #EB5757;
$info: #2D9CDB;
$success: #48D597; // #6FCF97;
$warning: #FFD041;

/* CUSTOM COLORS */
$primary: #2832aa;
$primary-sat-40: saturate($primary, 40%);
$secondary: #00CAF4;
$main: #707295;
$main-dark-40: #44455B;
$main-light-20: #ABACC0;
$main-light-40: #E2E3E9;
$light-bg: #F8F9FD;
$purple: #3A3865;

/* BOOTSTRAP COLORS */
$theme-colors: (danger: $danger, info: $info, success: $success, warning: $warning, primary: $primary, secondary: $secondary);

/* GRADIENT */
$gradient-bg: linear-gradient(131.1deg, $primary -2.73%, $secondary 151.8%);

/* PIXELS */
$base: 16;
$basepx: 16px;

@function px2em($px) {
  @return ($px / $base)+0em;
}

/* SHADOWS */
$shadow: 0 0 17px rgba(112, 114, 149, 0.25);
$dark-shadow: 0 0 17px rgba(112, 114, 149, 0.5);
$documents-shadow: 4px 0 12px -4px rgba(112, 114, 149, 0.25),
inset 4px 0 12px -4px rgba(112, 114, 149, 0.4);

/* OTHERS */
$letter-spacing: 0.04em;

@include css-vars((--primary: $primary,
    --secondary: $secondary,
    --gradient-bg: $gradient-bg,
    --danger: $danger,
    --info: $info,
    --success: $success,
    --warning: $warning));
