.nav-tabs {
  background-color: white;
  border-bottom: 0;
  box-shadow: $shadow;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;

  .nav-item {
    margin: 0;

    .nav-link {
      cursor: pointer;
      border: 0;
      border-radius: 0 !important;

      &.active {
        background-color: $light-bg;
        border-bottom: 2px solid $secondary;
      }
    }
  }

}

.tab-pane {
  margin-top: px2em(42);
  height: calc(100vh - #{px2em(214)});

  .lds-cube {
    position: absolute;
  }
}
