.filters {
  .title {
    padding: 0 !important;
    padding-top: px2em(4) !important;
    margin-bottom: px2em(16);
  }

  .main-btn {
    padding: px2em(8) px2em(16) !important;
    height: px2em(40);

    svg {
      margin-right: px2em(4);
    }
  }

  form {
    padding: 0 !important;
  }

  .filter-buttons {
    svg {
      font-size: px2em(28) !important;
    }

    .btn-link {
      color: $main-light-20;

      &.selected {
        color: $primary;
      }
    }
  }

  .input-group {
    background-color: transparent;
    color: $main;
    border: none !important;
    margin-left: px2em(18);
    font-size: px2em(14);

    svg {
      font-size: px2em(32);
    }

    .input-group-text {
      background-color: transparent !important;
      margin: 0 !important;
      z-index: 3;
    }

    input {
      box-sizing: border-box;
      border: 2px solid $main-light-40 !important;
      background-color: white !important;
      border-top-left-radius: px2em(4) !important;
      border-top-right-radius: px2em(4) !important;
      border-bottom-left-radius: px2em(4) !important;
      border-bottom-right-radius: px2em(4) !important;
    }

    input:focus {
      box-shadow: $shadow;
    }
  }

  .form-control {
    color: $main;

    &:focus {
      background-color: transparent;
    }
  }

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $main-light-20;
    opacity: 1;
    /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $main-light-20;
    font-weight: bold;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $main-light-20;
    font-weight: bold;
  }

  .year {
    input {
      padding: px2em(6) px2em(12);
    }
  }

  .search-filter {
    input {
      width: px2em(256);
    }
  }
}

.no-data {
  position: absolute;
  left: 0;
  right: 0;
  margin: px2em(180);
}

.no-data-table {
  padding: px2em(24) !important;
  background-color: white;
}
