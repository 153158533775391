.switch {
  margin-bottom: px2em(-8);
  margin-left: px2em(8);

  .react-switch-bg {
    box-sizing: border-box;
    border: 2px solid $main-light-20;
  }

  &.checked .react-switch-bg {
    border: 2px solid $secondary;
  }
}
