.users-list {
  min-height: calc(100vh - #{px2em(170)});

  .card-body {
    padding: px2em(16);
    background-color: white;
    max-height: unset;
    height: calc(100vh - #{px2em(188)});

    .table-responsive {
      height: calc(100vh - #{px2em(256)});
      overflow: hidden;
      font-size: 16px;

      .users-table {

        tbody {
          display: block;
          overflow: auto;
          height: calc(100vh - #{px2em(304)});

          @include scrollbar;
        }

        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed;

          th,
          td {
            text-align: left !important;
            padding: 0 !important;
            white-space: normal;
            overflow: visible;

            &:nth-child(2),
            &:last-child {
              width: px2em(100);
            }

            &:nth-child(4) span {
              display: block !important;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          th {
            font-weight: bold;
            padding: px2em(14) px2em(12) !important;

            &:last-child {
              width: px2em(114) !important;
            }
          }

          td span:not(.badge) {
            display: flex;
            align-items: center;
            padding: px2em(14) px2em(12) !important;
            min-height: px2em(67);

            svg {
              color: $main-dark-40;

              &.open {
                color: $primary;
              }
            }
          }
        }

        .buttons {
          display: flex;
          justify-content: flex-end;

          svg {
            color: $primary !important;
          }
        }

        .badges {
          margin: px2em(-2);

          .badge {
            background-color: $main-light-20;
            padding: px2em(4) px2em(8) !important;
            width: auto;
            text-transform: none;
            color: white;
            margin: 2px;
          }
        }
      }
    }
  }
}

.users #powered-by {
  position: absolute;
  bottom: px2em(16);
}
