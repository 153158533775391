html,
body {
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: scrollbar;
}

body {
  font-family: CustomFont, "Open Sans", "Helvetica Neue", sans-serif;
  color: $main;
  background-color: $light-bg;
}

/* MARGIN & PADDING --------------------------------- */
.row,
.col,
div[class*="col"],
.container,
.container-fluid {
  margin: 0;
  padding: 0 !important;
}

/* SHADOW --------------------------------- */

.shadow {
  box-shadow: $shadow !important;
}

/* ALTRO --------------------------------- */

a,
a:hover {
  color: $primary;
}

button,
a,
a:hover {
  text-decoration: none !important;
}

p {
  margin-bottom: 0;
}

.overflow-visible {
  overflow: visible !important;
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.bg-white {
  background-color: white;
}

.pull-right {
  justify-content: flex-end;
}

h3 {
  font-size: px2em(22);
  font-weight: bold;
}

iframe {
  border: 0;
}

/* COOKIESBANNER ---------------------------------- */

.cookie-message img {
  margin-right: 1em;
  max-width: 2.5em;
}

/* PAGE CONTAINER --------------------------------- */

.page-container {
  padding: px2em(16) !important;
  min-height: calc(100vh - #{px2em(60)});
  max-height: calc(100vh - #{px2em(60)});
  overflow-x: hidden;

  &.height-auto {
    min-height: unset;
  }

  .lds-cube {
    left: 0;
  }

  /* POWERED BY ------------------------------------ */

  .list-container {
    min-height: calc(100vh - #{px2em(170)});
    align-content: start;
  }

  div[class*="-detail"] {
    min-height: calc(100vh - #{px2em(116)});
  }

  &.order-detail {
    min-height: calc(100vh - #{px2em(60)});
  }

  &.profile>.row {
    min-height: calc(100vh - #{px2em(100)});
  }

  .gadget {
    min-height: calc(100vh - #{px2em(480)});
  }

  // &.order-detail #powered-by,
  .wizard+#powered-by {
    position: absolute;
    bottom: px2em(16);
  }
}

.file-preview #powered-by {
  display: none;
}

#powered-by {
  background: white;
  margin-left: px2em(-16);
  margin-bottom: px2em(-16);
  margin-right: px2em(-16);
  padding: px2em(8) px2em(16);
  box-shadow: $shadow;
  width: 120%;
}

.pdf-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;

  >div {
    height: 100%;
    width: 100%;

    .lds-cube {
      position: absolute;
      top: 0;
      background-color: $light-bg;
    }
  }
}

/* CUSTOM ICON */

.svg-wrapper .MuiSvgIcon-root {
  margin-left: 0;
}

.language-selector {
  width: auto;
  min-width: 6.6rem;
  text-transform: uppercase;
}

/* Language selector */

.k-list-container {
  z-index: 1001;
  cursor: pointer;
}

.flag-icon-gb {
  background-image: url('../images/flags/gb.svg');
}

.flag-icon-it {
  background-image: url('../images/flags/it.svg');
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  height: 1rem;
  line-height: 1em;
  margin-right: 0.5rem;
}

.k-dropdown-wrap {
  cursor: pointer;
}

.k-dropdown-wrap .k-input {
  border: 0px;
}

.k-dropdown-wrap .k-select {
  display: flex;
  align-items: center;
}

.language-selector-item {
  display: flex;
  padding: 0.75rem;
  flex-direction: row;
  align-content: stretch;
  align-items: center;
}
