.material-preview {
  background-color: white;
  height: px2em(304);
  width: px2em(168);
  padding: px2em(16);
  border-radius: px2em(4);
  margin: 0 auto;

  .card-body {
    text-align: center;

    .title {
      text-transform: uppercase;
      letter-spacing: 0.04em;
      font-size: px2em(14);
      color: $secondary;
      height: px2em(32);
    }

    .material-crop-img {
      overflow: hidden;
      position: relative;
      height: px2em(170);
      margin-bottom: px2em(16);
      margin-top: px2em(16);

      img {
        width: 100%;
        margin: 0 auto;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        cursor: zoom-in;
      }
    }
  }
}
