.order {
  background-color: white !important;
  margin-bottom: px2em(16);
  box-shadow: $shadow;
  border-radius: px2em(8);
  overflow: hidden;
  flex-direction: row;
  height: px2em(118);

  .order-crop-img {
    height: 100%;
    min-width: px2em(250);
    max-width: px2em(250);
    overflow: hidden;
  }

  .card-body {
    height: px2em(110) !important;
    padding: px2em(12) px2em(16) !important;

    .card-title {
      padding: 0;
      font-weight: normal;
    }

    .order-title {
      font-size: px2em(16);

      span {
        word-spacing: px2em(20);
      }

      a {
        font-size: px2em(20);
        font-weight: bold;
      }
    }

    .card-subtitle {
      padding: 0;
      padding-top: px2em(8);
    }

    .card-text {
      padding: 0;
      padding-top: px2em(10);
    }

    .order-branch,
    .order-number {
      color: $primary;
      margin-left: px2em(4);
      font-weight: 400;
    }

    .order-status {
      width: px2em(218) !important;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.orders-table {
  tr {

    th:last-of-type,
    td:last-of-type {
      width: px2em(218) !important;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 25% !important;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 15% !important;
    }

    th:nth-child(3) {
      padding-left: 0;
    }
  }
}
