.wip {
  background-color: white !important;
  height: 100%;

  svg,
  object,
  img {
    width: px2em(280);
    margin-top: px2em(100);
  }

  h4 {
    color: $main-light-20;
    position: absolute;
    top: px2em(250);
    left: 0;
    right: 0;
  }
}
