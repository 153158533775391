.fjs-container {
  background: transparent !important;
  border: 0 !important;
  font-size: px2em(16);
  overflow: visible;

  .fjs-col {
    background: white;
    padding-top: px2em(24);
    padding-bottom: px2em(24);
    max-width: px2em(250);
    min-width: auto;
    min-height: calc(100vh - #{px2em(112)});
    max-height: calc(100vh - #{px2em(112)});
    border: 0;
    box-shadow: $documents-shadow;
    overflow-x: hidden;

    &:last-child {
      max-width: unset;
    }

    @include scrollbar;
  }

  .fjs-item a {
    color: $main;
    padding: px2em(8) 0 px2em(8) px2em(36);

    span {
      margin-right: px2em(36);
      padding-left: px2em(36);
      overflow: visible;
      font-size: px2em(18);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;

      div {
        position: absolute;
        top: 2px;
        left: 0;
        padding: 0;
      }
    }

    i {
      padding-bottom: px2em(5);
    }
  }

  .fjs-active a,
  .fjs-col:last-child .fjs-active a,
  .fjs-active .fjs-has-children .fjs-item,
  .fjs-col:last-child .fjs-active a,
  .fjs-col:nth-last-child(2) .fjs-active a,
  .fjs-col:nth-last-child(2) .fjs-active a .fa-caret-right {
    color: $main;
    background-color: $main-light-40;
    box-shadow: $documents-shadow;
  }

  .fjs-item:hover {
    background-color: $light-bg;
    box-shadow: $documents-shadow;
  }
}
