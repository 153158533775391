.profile-card {
  .card-body {
    max-height: 100% !important;
  }
}

.profile-form {
  .form-section {
    .logo {
      position: absolute;
      height: px2em(16);
      top: px2em(4);
      left: px2em(220);
    }

    .edit-name {
      display: inline-flex;
      width: 100%;

      input {
        margin-right: px2em(16);
      }
    }

    .email {
      font-size: px2em(18);
      margin-bottom: px2em(24);
      line-height: px2em(20);

      a {
        color: $main-light-20 !important;
      }
    }

    .dynamic-input {
      position: relative;
      display: block;

      .input-group:last-of-type {
        margin-bottom: 0;
      }

      :not(.new-address) {
        .input-group-append {
          width: px2em(48);
        }
      }
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      height: px2em(14);
    }

    .new-address {
      input {
        padding-right: px2em(56);
      }
    }
  }

  .logos {
    margin-top: px2em(-16);

    div[class*="col"] {
      padding: px2em(16) !important;

      img {
        padding: px2em(16);
        background-color: $main-light-20;
        width: 100%;
        max-height: px2em(80);
        height: auto;
        position: relative;
      }
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .profile-form {
    .form-section {
      .logo {
        width: px2em(44);
        top: px2em(5);
      }
    }
  }
}
