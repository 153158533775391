.modal-content {
  border: 0;
  font-size: px2em(16);

  form {
    padding: 0 !important;
    padding-top: px2em(16) !important;
    font-size: px2em(14);
  }

  .modal-header {
    background: $gradient-bg;
    color: white !important;
    border: 0;
    font-weight: bold;

    button {
      display: none;
    }
  }

  .modal-body,
  .modal-footer {
    background-color: $light-bg;
    border: 0;
    border-radius: px2em(4);
    font-size: px2em(18);
  }
}
