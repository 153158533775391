.search-bar {
  margin-right: px2em(16);
  position: relative;

  .btn,
  .btn:hover,
  .btn:active {
    color: $main !important;
  }

  .search-input {
    width: px2em(320);
    position: absolute;
    top: px2em(-4);
    left: px2em(-320);
    background-color: white;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    .autocomplete-menu {
      div:first-child {
        font-weight: bold;
      }

      .search-result {
        .suggestion-type {
          font-weight: bold;
          font-size: px2em(13);
          margin-left: px2em(6);
        }
      }
    }

    .input-group-append {
      width: px2em(48);
    }
  }
}
