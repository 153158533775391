input[role="combobox"] {
  width: 100%;
  margin: 0;
  font-size: px2em(16);
  background-color: transparent;
  z-index: 100;
  height: px2em(36.5);
}

.autocomplete-menu {
  max-height: px2em(128);
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px px2em(12);
  background: white;
  font-size: px2em(18);
  overflow: auto;
  position: absolute;
  margin-bottom: px2em(32);
  margin-top: -1px;
  margin-left: -2px;
  border: 2px solid $secondary;
  box-sizing: content-box;
  border-bottom-left-radius: px2em(4);
  border-bottom-right-radius: px2em(4);
  z-index: 99;

  div {
    height: px2em(40);
    padding: px2em(8) px2em(16);
    cursor: pointer;
    border-bottom: 2px solid $main-light-40;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div:last-child {
    border-bottom: 0;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .autocomplete-menu {
    margin-top: 0;

    div {
      border-left: 1px solid $secondary;
    }
  }
}
