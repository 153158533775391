.file-uploader {
  position: relative;

  div {
    color: $main-light-20;
    margin-right: px2em(88);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  button {
    position: absolute;
    top: px2em(-2) !important;
    right: px2em(-2) !important;
    border-color: $main-light-20 !important;
    height: px2em(40);

    &:hover,
    &:focus {
      border-color: $main-light-20 !important;
    }
  }
}
