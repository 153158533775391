.need-help {
  position: fixed;
  bottom: 0;
  left: px2em(18);
  background-color: white;
  color: $primary;
  height: px2em(60);
  width: px2em(56);
  border-top-left-radius: px2em(8);
  border-top-right-radius: px2em(8);
  box-shadow: $shadow;
  cursor: pointer;
  z-index: 1000;

  .need-help-button {
    padding: px2em(10);
    outline: none;
    cursor: pointer;
    font-size: px2em(14);
    font-weight: bold;
    text-align: center;
  }

  &:focus {
    outline: none;
  }

  &.v1 {
    left: unset;
    right: px2em(48);
    bottom: px2em(96);
    background-color: $primary;
    color: white;
    width: px2em(140);
    height: px2em(44);
    border-radius: px2em(8);
    box-shadow: $dark-shadow;

    .need-help-button {
      font-size: px2em(16);

      svg {
        margin-left: px2em(4);
        margin-top: px2em(-2);
        margin-right: px2em(-8);
      }
    }
  }
}

.need-help-form {
  position: fixed;
  background-color: white;
  bottom: 0;
  left: px2em(18);
  height: auto;
  width: px2em(375);
  border-top-left-radius: px2em(8);
  border-top-right-radius: px2em(8);
  box-shadow: $shadow;
  z-index: 1001;

  &.open {
    z-index: 1010;
    transition-delay: 0s;
  }

  -webkit-transition: z-index 0.5s ease;
  -moz-transition: z-index 0.5s ease;
  -o-transition: z-index 0.5s ease;
  transition: z-index 0.5s ease;
  transition-delay: 2s;

  .need-help-header {
    border-top-left-radius: px2em(8);
    border-top-right-radius: px2em(8);
    height: px2em(40);
    background-color: $primary;
    color: white;
    font-size: px2em(16);
    padding: px2em(8) px2em(18);

    svg {
      position: absolute;
      right: px2em(24);
      padding-bottom: 2px;
      cursor: pointer;

      &:last-of-type {
        right: px2em(8);
      }
    }
  }

  form {
    padding: px2em(18) !important;

    .form-select {
      margin-bottom: px2em(64);
    }

    .btn-link {
      padding: 0;
      width: 100%;
      margin-bottom: px2em(16);
    }

    textarea {
      height: px2em(88);
    }

    .form-file {
      margin-bottom: px2em(54);
    }

    .btn-primary {
      position: absolute;
      bottom: px2em(18);
      right: px2em(18);
    }
  }

  &.v1 {
    left: unset;
    right: px2em(48);
    box-shadow: $dark-shadow;
  }
}
