.sidebar-nav {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  box-shadow: $shadow;
  overflow: hidden;

  .sidebar-brand {
    margin-top: px2em(18);
    margin-bottom: px2em(60);

    &:hover {
      background-color: transparent !important;
    }

    svg {
      padding-left: px2em(27);
      color: white;
      font-size: px2em(26);
      width: auto;
      cursor: pointer;
      margin-left: 0;
    }

    a {
      border: 0 !important;
      padding: 0;
      display: inline;

      .fade {
        display: inline;

        .logo {
          padding: 0;
          height: px2em(14);
          position: absolute;
          left: px2em(50);
          top: px2em(12);
        }

        h1.logo {
          display: inline;
          font-weight: bold;
          left: px2em(36);
          top: px2em(3);
        }

        .sidebar-brand-customer {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          position: absolute;
          left: px2em(80);
          top: px2em(44);

          span {
            font-size: px2em(12);
            margin-bottom: px2em(-4);
          }

          img {
            max-width: px2em(72);
            max-height: px2em(24);
            margin-left: px2em(6);
          }
        }
      }
    }

    a:hover,
    a:active,
    a:focus {
      background: transparent !important;
      border: 0 !important;
    }
  }

  li {
    box-sizing: border-box;
    width: px2em(250);
    white-space: nowrap;
    overflow: hidden;

    a {
      border-left: px2em(3) solid transparent;
      color: $light-bg;
      display: block;
      font-weight: bold;
      padding: px2em(10) px2em(36);
      text-decoration: none;
      font-size: px2em(18);
      margin-bottom: 2px;

      svg {
        color: white;
        padding-right: px2em(14);
        margin-bottom: 1px;
        font-size: px2em(22);
        width: auto;
      }

      span svg {
        position: relative;
        margin-left: px2em(24);
      }

      &:hover,
      &:active,
      &.active-link {
        border-left: px2em(3) solid white;
      }

      &.disabled,
      &.disabled svg {
        color: $main-light-20;
        cursor: default;
      }

      &.disabled:hover,
      &.disabled:active,
      &.disabled:focus {
        border-left: px2em(3) solid transparent;
      }
    }

    &:hover {
      a {
        border-left: px2em(3) solid white;
      }

      .sub-menu {
        a {
          border-left: px2em(3) solid transparent;
        }
      }
    }
  }

  .sub-menu {
    max-height: 0;
    width: px2em(250);
    padding-left: 0;
    background: rgba(255, 255, 255, 0.05);
    list-style: none;

    li {
      a {
        border-left: px2em(3) solid transparent;
        padding-left: px2em(88);

        &:hover,
        &:active,
        &.active-sub-link {
          background: rgba(255, 255, 255, 0.05);
        }

        &.disabled:hover,
        &.disabled:active,
        &.disabled:focus {
          border-left: px2em(3) solid transparent;
        }
      }
    }

    &.open {
      max-height: px2em(208);
    }
  }
}

/* TOGGLED --------------------------------- */

#wrapper.toggled {
  .sidebar-nav {
    overflow: visible;

    .sidebar-brand svg {
      padding: 0 px2em(10);
    }

    li {
      width: px2em(60);

      a {
        padding: px2em(10) px2em(12);

        span {
          padding-left: px2em(8);
        }

        &:hover {
          span {
            opacity: 1;
          }
        }
      }

      .sub-menu {
        max-height: 0;
      }

      &:hover {
        width: px2em(250) !important;
        background-color: $primary;
        -webkit-transition: unset;
        -moz-transition: unset;
        -o-transition: unset;
        transition: unset;

        a {
          border-left: px2em(3) solid white;

          span {
            opacity: 1;
          }
        }

        .sub-menu {
          max-height: px2em(100);
          width: px2em(250) !important;

          li {
            width: px2em(250) !important;

            a {
              border-left: px2em(3) solid transparent;
              padding-left: px2em(72);

              span {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .sub-menu {
      width: px2em(250);
      background-color: $primary;
      width: 0 !important;

      li {
        width: 0 !important;

        a span {
          opacity: 0;
        }
      }
    }
  }
}

/* TRANSITIONS --------------------------------- */

.sidebar-nav li a,
.sidebar-nav li a span,
.sidebar-brand svg {
  -webkit-transition: padding 0.5s ease;
  -moz-transition: padding 0.5s ease;
  -o-transition: padding 0.5s ease;
  transition: padding 0.5s ease;
}

.sidebar-nav li,
.sub-menu {
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
}

.sub-menu.open,
.sub-menu {
  -webkit-transition: max-height 0.5s ease;
  -moz-transition: max-height 0.5s ease;
  -o-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;
}

/* IE11 --------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .sidebar-nav {
    .sidebar-brand {
      svg {
        width: px2em(50);
        margin-left: px2em(-1);
        margin-top: px2em(-1);
        position: absolute;
      }

      a {
        margin-left: px2em(46);
      }

      .logo {
        padding: 0 !important;
        margin-left: px2em(-12);
        margin-top: px2em(-7);
        font-size: px2em(36) !important;
      }
    }

    li a {
      padding: px2em(10) px2em(24);

      svg {
        width: px2em(40);
        padding: 0;
      }
    }

    .sub-menu li a {
      padding-left: px2em(84) !important;
    }
  }

  #wrapper.toggled {
    .sidebar-nav {
      .sidebar-brand {
        svg {
          padding: 0;
          margin-left: px2em(-6.5) !important;
        }
      }

      li a {
        padding: px2em(10) 2px;

        svg {
          width: px2em(32);
        }

        .fade {
          svg {
            display: none;
          }
        }
      }

      .sub-menu li a {
        padding-left: px2em(72) !important;
      }
    }
  }

  .sidebar-nav li a,
  .sidebar-brand svg {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}
