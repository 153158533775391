#wrapper {
  position: absolute;
  top: px2em(60);
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: px2em(250);
}

.sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: px2em(250);
  width: px2em(250);
  height: 100%;
  margin-left: px2em(-250);
  background-image: var(--gradient-bg);
}

#page-content-wrapper {
  position: absolute;
  margin: 0;
  left: px2em(250);
  top: 0;
  bottom: 0;
  right: 0;

  .lds-cube {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* TOGGLED --------------------------------- */

#wrapper.toggled {
  padding-left: 0;

  .sidebar-wrapper {
    width: px2em(60);
  }

  .page-container,
  .top-nav,
  #page-content-wrapper {
    left: px2em(60);
  }

  .documents,
  .preview {
    left: px2em(60);
    width: calc(100vw - #{px2em(60)});
  }
}

/* TRANSITIONS --------------------------------- */
#wrapper,
.sidebar-wrapper,
#page-content-wrapper,
.top-nav,
.documents,
.preview,
.page-container {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* PADDING --------------------------------- */
.homepage,
.profile,
.order-detail {
  padding-top: 0 !important;

  .row {
    div[class^="col"]:first-child {
      .crd {
        margin-right: px2em(8);
      }
    }

    div[class^="col"]:last-child {
      .crd {
        margin-left: px2em(8);
      }
    }

    div[class^="col"]:only-child {
      .crd {
        margin: 0;
      }
    }
  }

  div[class^="row"]:last-child {
    .crd {
      margin-bottom: 0 !important;
    }
  }
}
