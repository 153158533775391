.stepper {
  width: px2em(376) !important;
  margin: 0 auto;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  a,
  span {
    line-height: px2em(28) !important;
    font-weight: bold !important;
  }

  div div div {
    margin-top: px2em(8) !important;
    padding-top: 0 !important;
    font-weight: bold !important;

    a {
      margin-top: px2em(4) !important;
    }

    div {
      top: 16px !important;
      border-width: px2em(4) !important;

      a {
        color: $secondary !important;
      }

      span {
        color: $main-light-40 !important;
      }
    }

    div:nth-child(2) {
      margin-top: px2em(6) !important;
    }
  }

  &.step-1 {
    div div div:first-of-type div a {
      color: white !important;
    }
  }

  &.step-2 {
    div div div div a {
      color: white !important;
    }

    div div div:last-of-type div a {
      color: $secondary !important;
    }
  }

  &.step-3 {
    div div div div a {
      color: white !important;
    }
  }
}
