input,
.file-uploader {
  border: 2px solid $main-light-40;
  background-color: white;
  color: $main;
  font-size: px2em(16);
  box-sizing: border-box;
  border-radius: px2em(4);
  outline: none;
  padding: px2em(6) px2em(16);
  height: px2em(40);
}

input:disabled,
select:disabled,
.form-control:disabled {
  border: 2px solid $main-light-20;
  background-color: transparent;
}

.form-control::placeholder,
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $main-light-20;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $main-light-20;
  font-weight: bold;
}

.form-control::-ms-input-placeholder input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $main-light-20;
  font-weight: bold;
}

input[type="number"] {
  width: px2em(84) !important;
  padding: px2em(6) px2em(16);
}

textarea,
textarea.form-control {
  padding: px2em(6) px2em(16);
  resize: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

input::-ms-clear {
  display: none;
}

.sublabel {
  font-size: px2em(12);
  display: block;
  color: $danger !important;
}
