.order-detail {
  >.row {
    min-height: calc(100vh - #{px2em(100)});
  }

  .card .card-body {
    max-height: none;

    .form-section {
      margin-bottom: px2em(24);
    }
  }

  .card-chat .card-body {
    position: relative;
  }
}
