.help-chat {
  margin-top: px2em(42);
  margin-bottom: px2em(68);
  height: calc(100vh - #{px2em(284)});
  padding: px2em(16);
  overflow-y: auto;
  display: block;
  position: relative;
  background-color: white;

  svg {
    float: left;
    margin-top: px2em(12);
    margin-right: px2em(8);
    color: $main-light-20;
  }

  /* MESSAGE ----------------------------------- */
  .help-chat-message {
    float: right;
    padding: px2em(8) px2em(16);
    border-radius: px2em(4);
    background-color: $primary;
    box-shadow: $shadow;
    margin-bottom: px2em(16);
    width: 60%;
    color: white;

    &.left {
      float: left;
      background-color: white;
      color: $main;
    }

    .message-header {
      font-size: px2em(12);
      margin-bottom: px2em(8);
      opacity: 0.9;
    }
  }
}
