.user-modal {

  .row {
    div[class*="col"][class*="6"] {
      &:first-child {
        padding-right: px2em(12) !important;
      }

      &:last-child:not(:first-child) {
        padding-left: px2em(12) !important;
      }
    }
  }

  .form-group {
    margin-bottom: px2em(16) !important;
  }
}
